<template>
  <router-view></router-view>
</template>

<style lang="scss">
:root {
  --bs-blue: #0a192f;
  --bs-cyan: #5ff1d0;
  --bs-gray: #8791af;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray);
  border: 3px solid var(--bs-blue);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: var(--bs-blue);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bs-blue);
}
</style>
